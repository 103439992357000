

























import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Experiment from '@/models/experiment/Experiment';
import SuperTask from '@/models/experiment/SuperTask';
import TaskItem from '@/components/experiment/TaskItem.vue';
import TaskService from '@/services/TaskService';
import { Prop } from 'vue-property-decorator';
import Utils from '@/utils/Utils';

@Component({
  components: { TaskItem },
})
export default class ExperimentItem extends mixins(Toasts) {
  @Prop({ required: true })
  protected experiment!: Experiment;
  protected tasks: SuperTask[] = [];
  protected interval: unknown;
  protected isLoaded = false;
  protected linkify = Utils.linkify;

  mounted(): void {
    this.loadTasks();
  }

  protected loadTasks(): void {
    this.setLoading(true);
    TaskService.getAllTasksForExperiment(this.experiment.id ?? -1)
      .then((result) => {
        this.setLoading(false);
        this.tasks = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load tasks. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected setLoading(loading: boolean): void {
    if (!this.isLoaded) {
      this.$root.$data.loading = loading;
    }
  }
}
