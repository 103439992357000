















import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Vue from 'vue';
import Submission from '@/models/experiment/Submission';

@Component({})
export default class TaskState extends Vue {
  @Prop({ required: true })
  protected submissions!: Submission[];

  protected stateClass(): string {
    if (this.submissions.length === 0) {
      return 'failed';
    } else if (this.submissions[0].completed) {
      return 'finished';
    } else {
      return 'running';
    }
  }

  protected stateDescription(): string {
    if (this.submissions.length === 0) {
      return 'Unsolved';
    } else if (this.submissions[0].completed) {
      return 'Solved';
    } else {
      return 'Started';
    }
  }

  protected handleClick(event: Event): void {
    event.preventDefault();
    this.$emit('click');
  }
}
