











































































































































import moment from 'moment';
import ProjectService from '../../services/ProjectService';
import { Component } from 'vue-property-decorator';
import Project from '../../models/Project';
import UserInfo from '@/models/users/UserInfo';
import { State } from 'vuex-class';
import { getCurrentUserID, hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import TaskListForParticipant from '@/components/experiment/TaskListForParticipant.vue';
@Component({
  components: { TaskListForParticipant },
})
export default class Projects extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project): boolean {
    const permission = hasPermission(action, project);
    return permission != null && permission;
  }

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Projects', active: true },
  ];

  protected projects: Project[] = [];

  protected projectCreationInProgress = false;
  protected projectCreationEnteredName = '';
  protected projectCreationValidatorState: boolean | null = null;
  protected projectCreationErrorMessage = '';
  protected projectCreationSelectedOwners: UserInfo[] = [];
  protected projectCreationSelectedMembers: UserInfo[] = [];

  protected currentUserID = '';

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_GET_PROJECT)) {
      this.currentUserID = getCurrentUserID();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
      this.$on('finished-delete-project', (args: any) => {
        this.projects.splice(this.projects.indexOf(args.project), 1);
      });
      this.loadProjects();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  beforeDestroy(): void {
    this.$off('finished-delete-project');
  }

  protected loadProjects(): void {
    if (hasPermission(PossibleAction.CAN_GET_PROJECT)) {
      this.setLoading(true);
      ProjectService.getAll()
        .then((projects) => {
          this.showToast('Successfully loaded', projects.length + ' Projects found.', 'info');
          this.projects = projects.reverse();
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load projects.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected createProject(event: Event): void {
    if (hasPermission(PossibleAction.CAN_SAVE_PROJECT)) {
      event.preventDefault();
      if (this.projectCreationEnteredName.length > 0 && this.projectCreationEnteredName.length < 256) {
        this.projectCreationInProgress = true;
        const project = new Project(this.projectCreationEnteredName);
        this.projectCreationSelectedOwners.forEach((value) => {
          project.owners.push(value.uid);
        });
        if (project.owners.indexOf(this.currentUserID) === -1) {
          project.owners.push(this.currentUserID);
        }
        this.projectCreationSelectedMembers.forEach((value) => {
          project.members.push(value.uid);
        });
        ProjectService.save(project)
          .then((savedProject) => {
            this.$nextTick(() => {
              this.showToast(
                'Successfully created',
                "Project '" + savedProject.name + "' successfully created.",
                'success'
              );
              this.projects.unshift(savedProject);
              this.$bvModal.hide('modal-create');
            });
          })
          .catch((backendError) => {
            if (backendError.response.status === 409) {
              this.projectCreationValidatorState = false;
              this.projectCreationErrorMessage = 'Name is already in use for a project.';
            } else if (backendError.response.status === 403) {
              this.$bvModal.hide('modal-create');
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
            } else {
              this.showToast('Failed to create', 'Could not create project: ' + backendError.response.status, 'danger');
            }
          })
          .finally(() => (this.projectCreationInProgress = false));
      } else {
        if (this.projectCreationEnteredName.length <= 0 || this.projectCreationEnteredName.length > 255) {
          this.projectCreationValidatorState = false;
          this.projectCreationErrorMessage = 'Required (max. Length 255)';
        } else {
          this.projectCreationValidatorState = null;
        }
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected deleteProject(project: Project): void {
    if (hasPermission(PossibleAction.CAN_DELETE_PROJECT, project)) {
      this.$root.$emit('delete-project', { project, target: this });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected openProjectDetails(project: Project): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS) && project && project.id) {
      this.$router.push({
        name: 'projectDetails',
        params: { projectId: project.id.toString() },
      });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected getPossibleOwners(): UserInfo[] {
    return this.availableUsers.filter((user) => {
      const roles = user.roles.map((role) => UserRoleEnum[role]);

      let canOwnProject = roles.indexOf(UserRoleEnum.ROLE_ADMIN) !== -1;
      if (!canOwnProject) {
        canOwnProject = roles.indexOf(UserRoleEnum.ROLE_DESIGNER) !== -1;
      }
      return (
        canOwnProject && this.projectCreationSelectedMembers.indexOf(user) === -1 && user.uid !== this.currentUserID
      );
    });
  }

  protected getPossibleMembers(): UserInfo[] {
    return this.availableUsers.filter((user) => {
      return this.projectCreationSelectedOwners.indexOf(user) === -1 && user.uid !== this.currentUserID;
    });
  }

  protected projectCreationReset(): void {
    this.projectCreationEnteredName = '';
    this.projectCreationValidatorState = null;
    this.projectCreationSelectedOwners = [];
    this.projectCreationSelectedMembers = [];
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
