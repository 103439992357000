































































import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Task from '@/models/experiment/Task';
import TaskService from '@/services/TaskService';
import { TaskTypeEnum } from '@/models/experiment/TaskTypeEnum';
import ReviewTask from '@/models/experiment/ReviewTask';
import GoogleFormsTask from '@/models/experiment/GoogleFormsTask';
import SuperTask from '@/models/experiment/SuperTask';
import Submission from '@/models/experiment/Submission';
import UserService from '@/services/UserService';
import { getCurrentUserID } from '@/auth/AuthService';
import UserInfo from '@/models/users/UserInfo';
import SubmissionService from '@/services/SubmissionService';
import TaskState from '@/components/experiment/TaskState.vue';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import ReviewService from '@/services/ReviewService';
import { Prop } from 'vue-property-decorator';
import Utils from '@/utils/Utils';

@Component({
  components: { TaskState },
})
export default class TaskItem extends mixins(Toasts) {
  protected superTask!: SuperTask;
  protected submissions: Submission[] = [];
  protected loadedTask!: Task;
  protected loadedUser!: UserInfo;
  protected loadedReviewTask!: ReviewTask;
  protected loadedGoogleTask!: GoogleFormsTask;
  @Prop({ required: true })
  protected taskId!: number;
  protected taskType: TaskTypeEnum = TaskTypeEnum.NORMAL;
  protected loaded = false;
  protected interval!: number;
  protected linkify = Utils.linkify;

  mounted(): void {
    this.$on('finished-review-creation', (args) => {
      const review = args.review;
      this.$router.push({
        name: 'reviewProcess',
        params: { assignmentId: review.assignmentId, reviewId: review.id },
      });
    });
    this.loadUser();
    this.getTaskById();

    this.interval = window.setInterval(() => {
      this.getTaskById();
    }, 120000);
  }

  destroyed(): void {
    clearInterval(this.interval);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected getTaskById(): void {
    TaskService.getTaskType(this.taskId)
      .then((result) => {
        this.taskType = result;
        this.loadTaskWithType();
        this.getSubmissionOfUser();
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load task. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  private getSubmissionOfUser(): void {
    this.setLoading(true);
    SubmissionService.getAllSubmissionsOfTaskForUser(this.taskId)
      .then((result) => {
        this.setLoading(false);
        this.submissions = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load submission. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected openGoogleForms(): void {
    window.open(
      'https://docs.google.com/forms/d/e/' +
        this.loadedGoogleTask.formsId +
        '/viewform?usp=pp_url&entry.' +
        this.loadedGoogleTask.formsMatriculationNumberFieldId +
        '=' +
        this.loadedUser.matriculationNumber,
      '_blank'
    );
  }

  protected openReview(): void {
    ReviewAssignmentService.getDTOById(this.loadedReviewTask.reviewAssignment)
      .then((result) => {
        this.$root.$emit('start-review-process', { assignment: result, target: this });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load review assignment. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected resumeReview(): void {
    ReviewAssignmentService.getDTOById(this.loadedReviewTask.reviewAssignment)
      .then((result) => {
        ReviewService.getAllByAssignmentId(this.loadedReviewTask.reviewAssignment)
          .then((reviews) => {
            const inProgressReviewsOfUser = reviews.filter(
              (r) => r.userId === getCurrentUserID() && r.state.toString() == 'IN_REVIEW'
            );
            if (inProgressReviewsOfUser.length === 1) {
              this.$root.$emit('resume-review-process', {
                assignment: result,
                review: inProgressReviewsOfUser[0],
                target: this,
              });
            } else {
              this.showToast('Failed to load', 'No open review found or more than one open review found.', 'danger');
            }
          })
          .catch((backendError) => {
            if (backendError.response.status !== 403) {
              this.showToast('Failed to load', 'Could not load the reviews.', 'danger');
            }
          });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load review assignment. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  private loadUser(): void {
    UserService.getUserInfoById(getCurrentUserID())
      .then((res) => {
        this.loadedUser = res;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load user. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  private loadTaskWithType(): void {
    if (this.taskType === TaskTypeEnum.NORMAL) {
      TaskService.getNormalTaskById(this.taskId).then((result) => {
        this.superTask = result as SuperTask;
        this.loadedTask = result;
        this.loaded = true;
      });
    } else if (this.taskType === TaskTypeEnum.REVIEW) {
      TaskService.getReviewTaskById(this.taskId).then((result) => {
        this.superTask = result as SuperTask;
        this.loadedReviewTask = result;
        this.loaded = true;
      });
    } else {
      TaskService.getGoogleFormsTaskById(this.taskId).then((result) => {
        this.superTask = result as SuperTask;
        this.loadedGoogleTask = result;
        this.loaded = true;
      });
    }
  }
}
