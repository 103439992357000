









import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Experiment from '@/models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';
import ExperimentItem from '@/components/experiment/ExperimentItem.vue';
@Component({
  components: { ExperimentItem },
})
export default class TaskListForParticipant extends mixins(Toasts) {
  protected experiments: Experiment[] = [];

  mounted(): void {
    this.getAllExperimentsForUser();
  }

  protected getAllExperimentsForUser(): void {
    ExperimentService.getAllExperimentsForUser()
      .then((res) => {
        this.experiments = res;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load experiments. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
